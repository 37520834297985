import React from "react";
import Helmet from "react-helmet";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

import { FaPlay } from 'react-icons/fa';

import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import BlogShortParallax from "components/ShortParallax/BlogShortParallax.jsx";
import blogPageStyle from "assets/jss/material-kit-react/views/blogPageStyle.jsx";
import BlogSubscribeSection from '../LandingPage/Sections/BlogSubscribeSection';
import BlogSection from "./Sections/BlogSection.jsx";


const dashboardRoutes = [];

class BlogPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
           color="transparent"
           brand="BookMerlin"
           rightLinks={<HeaderLinks />}
           fixed
           changeColorOnScroll={{
             height: 50,
             color: "#009688"
           }}
           {...rest}
        />
        <BlogShortParallax filter image={require("assets/img/background.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem >
                <h1 className={classes.title}>Welcome to the Community!</h1>
              </GridItem>
            </GridContainer>
          </div>
        </BlogShortParallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div >
            <BlogSection />
          </div>
          <div className={classes.blogSubscribe}>
            <BlogSubscribeSection />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(blogPageStyle)(BlogPage);
